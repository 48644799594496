@import '../variables.scss';

.replenish-title {
  color: get-color('midnight-blue');
}

.replenish-description {
  color: get-color('emperor');
}

.card-title {
  font-weight: 600;
}

.card-radius {
  border-radius: 24px;
  padding: 32px;
}

.card-badge {
  border-radius: 50px;
  padding: 8px 16px 8px 16px;
  background-color: rgba($color: get-color('dodger-blue'), $alpha: 0.2);
  color: get-color('mariner');
  overflow: hidden;
}

.card-credits {
  color: get-color('mariner');
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.card-button {
  border-radius: 12px;
  margin-top: auto;
  font-size: 22px;
  width: 100%;
}

.card-cost {
  font-size: 30px;
  margin-right: 10px;
}

.card-success {
  margin: 30px auto;
  width: 30%;
  height: 500px;
}

.card-success-border {
  border: get-color('silver') 1px solid;
}

.spinner {
  margin: 0 auto;
  margin-top: 10px;
  width: 3rem;
  height: 3rem;
}

.spinner-success {
  margin: 0 auto;
  width: 100px;
  height: 100px;

}

.spiner-contaner {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
}

@media (max-width: 1228px) {
  .card-cost {
    font-size: 26px;
  }

  .card-badge {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .card-success {
    width: 50%;
  }

}

@media (max-width: 930px) {
  .card-cost {
    font-size: 22px;
  }

  .card-badge {
    padding: 8px;
  }

  .card-button {
    font-size: 20px;
  }

  .card-success {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .card-success {
    width: 90%;
  }

}
