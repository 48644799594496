.footer {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 75px;
  background-image: url("../../../public/images/footer.png");
  background-size: cover;
  z-index: 11;
  position: relative;
}

@media (max-width: 766px) {
  .footer {
    padding: 40px 30px;
  }
}