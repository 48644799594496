.heading1 {
  font-size: 85px;
  line-height: 94px;
  font-family: "Faktum Semi Bold";
}

p {
  font-family: "Inter";
}

.menu-open {
  overflow: hidden;
}

.heading2 {
  font-size: 36px;
  line-height: 43px;
  font-family: "Faktum Semi Bold";
}

.heading3 {
  font-size: 24px;
  line-height: 32px;
  font-family: "Faktum Semi Bold";
}

.heading-colored {
  font-size: 40px;
  font-family: "Faktum Semi Bold";
  line-height: 48px;
  background: linear-gradient(
    151deg,
    rgba(52, 129, 204, 1) 29%,
    rgba(255, 109, 115, 1) 65%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  h3 {
    font-size: 40px;
    font-family: "Faktum Semi Bold";
    line-height: 48px;
  }
}

.body2 {
  font-size: 16px;
  line-height: 24px;
}

.body3 {
  font-size: 22px;
  line-height: 30px;
}

.body4 {
  font-size: 20px;
  line-height: 27px;
}

.sectionLanding {
  padding: 100px;
}

.z-index-10 {
  position: relative;
  z-index: 10;
}

.bottomBackground {
  background: black;
  background: linear-gradient(
    151deg,
    rgba(52, 129, 204, 1) 29%,
    rgba(255, 109, 115, 1) 65%
  );
}

.gradientBody {
  margin: 0;
  height: 100%;
  background: linear-gradient(
    151deg,
    rgba(52, 129, 204, 1) 29%,
    rgba(255, 109, 115, 1) 65%
  );
}

.nav-link {
  font-size: 17px;
  cursor: pointer;
}

.nav-link-large-text {
  font-size: 22px;
  font-family: "Faktum Semi Bold";
  cursor: pointer;
}

.info-label {
  font-size: 32px;
  line-height: 43px;
  color: white;
  opacity: 0.6;
}

.info-card {
  padding: 32px 24px;
}

.u-bg-blue {
  background-color: #3481cc;
}

.u-bg-gradient {
  background: rgb(52, 129, 204);
  background: linear-gradient(
    151deg,
    rgba(52, 129, 204, 1) 29%,
    rgba(255, 109, 115, 1) 65%
  );
}

.u-bg-link-water {
  background-color: #d2ecf4;
}

.u-decoration-none {
  text-decoration: none;
  color: white;
}

.close-success {
  border: none;
  background: inherit;
  color: white;
}

.u-bg-white {
  background-color: white;
}

.u-mb-58 {
  margin-bottom: 58px;
}

.u-text-center {
  text-align: center;
}

.u-width-60 {
  width: 60%;
}

.u-m-auto {
  margin: 0 auto;
}

.u-mr-3 {
  margin-right: 3px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-ml-50 {
  margin-left: 50px;
}

.u-ml-30 {
  margin-left: 30px;
}

.u-ml-20 {
  margin-left: 20px;
}

.u-ml-10 {
  margin-left: 10px;
}

.u-mb-40 {
  margin-bottom: 40px;
}

.u-mb-10 {
  margin-bottom: 10px;
}

.u-mb-30 {
  margin-bottom: 30px;
}

.u-mb-105 {
  margin-bottom: 105px;
}

.u-p-50 {
  padding: 50px;
}

.u-bold {
  font-weight: bold;
}

.u-text-white {
  color: white;
}

.u-width-100 {
  width: 100%;
}

.u-h-main-bg {
  height: 500px;
}

.box-shadow {
  z-index: 12;
  position: relative;
  box-shadow: 2px -85px 400px 24px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 2px -85px 400px 24px rgba(0, 0, 0, 1);
  -moz-box-shadow: 2px -85px 400px 24px rgba(0, 0, 0, 1);
}

.u-d-flex {
  display: flex;
}

.button {
  font-size: 22px;
  border-radius: 50px;
  padding: 16px 23px;
  border: none;
}

.grid-rows-3 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.u-rounded {
  border-radius: 25px;
  z-index: 10;
}

.u-d-none {
  display: none;
}

@media (min-width: 1200px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:h-\[539px\] {
    height: 539px;
  }
}

@media (max-width: 990px) {
  .heading1 {
    font-size: 60px;
    line-height: 68px;
  }

  .u-d-lg-none {
    display: none;
  }

  .u-pl-lg-0 {
    padding-left: 0;
  }

  .u-d-lg-block {
    display: block;
  }
}

@media (max-width: 768px) {
  .heading1 {
    font-size: 40px;
    line-height: 44px;
  }

  .u-flex-sm-column {
    flex-direction: column;
  }

  .u-mb-sm-90 {
    margin-bottom: 90px;
  }

  .u-mt-sm-50 {
    margin-top: 50px;
  }

  .u-mb-sm-40 {
    margin-bottom: 40px;
  }

  .u-ml-sm-0 {
    margin-left: 0;
  }
}

@media (min-width: 640px) {
  .sm\:w-\[80\%\] {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .heading1 {
    font-size: 40px;
    line-height: 44px;
  }

  .heading2 {
    font-size: 24px;
    line-height: 28px;
  }

  .heading3 {
    font-size: 20px;
    line-height: 27px;
  }

  .info-label {
    font-size: 18px;
    line-height: 24px;
  }

  .heading-colored {
    font-size: 28px;
    line-height: 34px;
  }

  .body3 {
    font-size: 16px;
    line-height: 21px;
  }

  .body4 {
    font-size: 14px;
    line-height: 18px;
  }

  .sectionLanding {
    padding: 30px;
  }

  .u-h-main-bg {
    height: 200px;
  }

  .u-width-sm-100 {
    width: 100%;
  }

  .heading-colored {
    h3 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .u-mb-sm-15 {
    margin-bottom: 15px;
  }
}
