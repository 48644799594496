@import "../variables.scss";

.error {
  color: get-color("maroon-flush");

  &::before {
    display: inline;
    content: "⚠ ";
  }
}

.link {
  text-decoration: none;
  color: get-color("black");
}

.btn-contaner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loginButton {
  border: 2px solid get-color("danube");
  background-color: transparent;
  color: get-color("black");
}

.loginButton:hover {
  background-color: rgba(get-color("danube"), 0.1);
  color: get-color("black");
}

.kep-login-facebook.medium {
  width: 100%;
  border-radius: 12px;
}

.btn-google {
  margin-right: 12px;
}

.btn-fb {
  display: flex;
  align-items: flex-end;
  border-radius: 50px;
  text-decoration: none;
  margin-left: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  aspect-ratio: 1;
  padding: 7px;
  min-width: 18px;
  border: none;

  .logo {
    height: inherit;
    display: flex;
    align-items: center;
    width: 100%;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  p {
    width: 100%;
    line-height: 1;
    letter-spacing: 0.21px;
    text-align: center;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: get-color("white");
    margin: 0;
  }
}

.btn-fb {
  padding-top: 1.5px;
  background: get-color("san-marino");

  &:hover {
    box-shadow: 0 0 3px 3px rgba(get-color("chambray"), 0.3);
  }

  &:active {
    background-color: get-color("chambray");
  }

  .fb-content p {
    color: rgba(get-color("white"), 0.87);
  }
}

.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.facebook-custom {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #DADCE0;
  border-radius: 5px;
  padding: 5px 0;

  &:hover {
    background: #F8FAFF !important;
  }
}

.facebook-custom p {
  letter-spacing: 0.25px;
  color: #3c4043;
  flex-grow: 1;
  margin: 0;
  font-family: "Google Sans",arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
