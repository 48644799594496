@import '../variables.scss';

.chat {
  border-radius: 12px;
  background: rgb(235, 235, 235);
  position: relative;
  height: auto;
  padding: 20px;
}
.left {
  border-bottom-left-radius: 0;
}
.right {
  border-bottom-right-radius: 0;
}
.chat::after {
  content: '';
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  bottom: 0;
}
.left::after {
  left: 0;
  transform: translateX(-100%);
  background: radial-gradient(circle at 0 0, transparent, transparent 72%, rgb(235, 235, 235) 72%);
}
.right::after {
  right: 0;
  transform: translateX(100%);
  background: radial-gradient(circle at 100% 0, transparent, transparent 72%, rgb(235, 235, 235) 72%);
}

.buttonMessage {
  margin: 3px;
  width: 100px;;
}

.minus-credit {
  font-size: 12px;
  margin-top: 3px;
  margin-left: 2px;
}

#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(get-color('midnight-blue'), 0.1);
}

.card-body {
  padding: 0 20px 20px 20px;
}

.btn-blue {
  background-color: get-color('danube');
  border: get-color('danube') 1px solid;
  color: get-color("white");
}

.btn-blue:hover {
  background-color: #3381cc;
  border: #3381cc 1px solid;
  color: get-color("white");
}

.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: get-color('midnight-blue');
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: get-color('midnight-blue');
  font-family: "Poppins", sans-serif;
}

.btn {
  font-weight: 500;
}

.modal-text {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}

.small-description {
  font-size: 10px;
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: get-color('nepal');
  font-weight: 600;
}

.progressVisualFull {
  display: flex;
  height: 9px;
  margin: 2px 0;
  width: 400px;
  border: 1px get-color('emperor') solid;
  border-radius: 15px;
}

.progressVisualPart {
  transition: width 2s;
}

.error {
  color: get-color('maroon-flush');

  &::before {
    display: inline;
    content: "⚠ ";
  }
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

.feedback-disclaimer {
  font-family: "Google Sans", arial, sans-serif;
  font-style: italic;
  margin-bottom: 30px;
  border-left: 3px solid lightgray;
  padding-left: 10px;
}
