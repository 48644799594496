@import "../variables.scss";

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: 40px;
}

.dashboard-chart {
  grid-column: span 2;
  margin-top: 40px;
}

.focus-area-title {
  color: get-color("flamingo");
}

.error-list-title {
  color: get-color("red");
}

.card-dashboard {
  max-width: 400px;
  font-size: 25px;
  line-height: 35px;
  margin: 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-list-item {
  max-height: 400px;
  @media (max-width: 990px) {
    max-height: unset;
  }
}

@media (max-width: 990px) {
  .dashboard-container {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .dashboard-chart {
    grid-column: 1;
  }
}

.error-list {
  overflow: scroll;
  height: 100%;
}

.spiner-container {
  height: 50vh;
}

.time-style {
  color: #5898d3;
  font-weight: bold;
}

.spinner {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}
