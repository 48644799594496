.image-bg {
  background-color: black;
  max-width: 100%;
  position: absolute;
  z-index: 0;
}

.card2-bg {
  background-image: url("../../../public/images/card2.png");
  background-color: white;
  background-size: cover;
}

.card3-bg {
  background-image: url("../../../public/images/card3.png");
  background-color: white;
  background-size: cover;
}

.heading1Description {
  font-size: 83px;
  line-height: 95px;
  font-family: "Inter";
  font-weight: 300;
}

.signUpButton {
  width: 190px;
}

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
}

.step .circle {
  border: 3px solid gray;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: inline-block;
}

.step .line {
  top: 40%;
  left: 21px;
  height: 100%;

  position: absolute;
  border-left: 3px solid gray;
  border-width: 1px;
}

.step.completed .circle {
  visibility: visible;
  background-position: center;
  border-color: rgb(255, 255, 255);
}

.step.completed .circle.one {
  background-image: url("../../../public/images/one.svg");
}

.step.completed .circle.two {
  background-image: url("../../../public/images/two.svg");
}

.step.completed .circle.three {
  background-image: url("../../../public/images/three.svg");
}

.step.completed .line {
  border-left: 3px solid rgb(255, 255, 255);
  border-width: 1px;
  height: 60%;
}

.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
}

.accordion-button::after {
  margin-left: inherit;
  position: absolute;
  left: 8px;
}

.accordion-button {
  background-color: black;
  border: none;
  justify-content: space-between;
  box-shadow: none;
}

.accordion-button p {
  font-size: 28px;
  font-weight: bold;
  color: white;
  width: 60%;
  border-radius: 0;
}

.accordion-button:not(.collapsed) {
  background-color: black;
  border: none;
  color: white;
  box-shadow: none;
  border-radius: 0;
}

.accordion-body {
  background-color: black;
  display: flex;
  justify-content: end;
}

.accordion-body p {
  font-size: 22px;
  width: 60%;
  color: gray;
}

.accordion-item {
  border: none;
  border-radius: 0;
  background-color: black;
}

.accordion-button:focus {
  border-color: black;
  box-shadow: none;
}

.accordion-button::after {
  background-image: none;
  width: 0;
  height: 0;
}

.Button--toggler {
  background: transparent;
  display: block;
  height: 30px;
  margin: 10px 0 0;
  padding: 0;
  width: 30px;

  &:focus {
    outline: 1px solid rgba(white, 0.2);
    outline-offset: 5px;
  }

  .collapsed & {
    .Button-plus {
      opacity: 0.7;

      .Button-plusVertical,
      .Button-plusHorizontal {
        transform: rotate(90deg);
      }

      .Button-plusHorizontal {
        opacity: 1;
      }
    }
  }

  .Button-plus {
    display: block;
    height: 25px;
    opacity: 1;
    position: relative;
    width: 25px;

    .Button-plusVertical,
    .Button-plusHorizontal {
      background-color: white;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: rotate(-90deg);
      transition: all 0.3s ease-in-out;
    }

    .Button-plusVertical {
      height: 25px;
      margin-left: -1.5px;
      margin-top: -12.5px;
      width: 3px;
    }

    .Button-plusHorizontal {
      height: 3px;
      margin-left: -12.5px;
      margin-top: -1.5px;
      opacity: 0;
      width: 25px;
    }
  }
}

@media (max-width: 990px) {
  .heading1Description {
    font-size: 60px;
    line-height: 68px;
  }
}

@media (max-width: 768px) {
  .heading1Description {
    font-size: 40px;
    line-height: 44px;
  }

  .accordion-button p {
    width: 80%;
  }

  .accordion-body p {
    width: 80%;
  }
}
