.cost {
  font-size: 40px;
  color: #3481cc;
  line-height: 48px;
  font-weight: 500;
}

.numbers {
  font-size: 75px;
  color: #000;
  line-height: 90px;
  font-weight: 600;
}

.numbers.numbers-ten {
  font-size: 95px;
}

.price1-bg {
  background-image: url("../../../public/images/price1.png");
  background-color: white;
  background-size: cover;
}

.price2-bg {
  background-image: url("../../../public/images/price2.png");
  background-color: white;
  background-size: cover;
}

.price3-bg {
  background-image: url("../../../public/images/price3.png");
  background-color: white;
  background-size: cover;
}

.u-bg-pricing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: #000;
  z-index: 0;
}

.buttonPrice {
  width: 120px;
}

@media (max-width: 576px) {
  .cost {
    font-size: 32px;
    line-height: 38px;
  }

  .numbers {
    font-size: 50px;
    line-height: 60px;
  }

  .numbers.numbers-ten {
    font-size: 50px;
  }
}
