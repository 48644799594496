.List {
  list-style: none;

  &-itemNumber {
    padding-left: 60px;
    margin-bottom: 40px;

    &::before {
      background-size: cover;
      display: inline-block;
      height: 0;
      position: absolute;
      color: white;
      transform: translate(-200%, 50%);
      width: 40px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &-itemNumber.one {
    &::before {
      content: "1";
    }
  }

  &-itemNumber.two {
    &::before {
      content: "2";
    }
  }

  &-itemNumber.three {
    &::before {
      content: "3";
    }
  }

  &-itemNumber.four {
    &::before {
      content: "4";
    }
  }

  &-itemNumber.five {
    &::before {
      content: "5";
    }
  }

  &-itemNumber.six {
    &::before {
      content: "6";
    }
  }

  &-itemNumber.seven {
    &::before {
      content: "7";
    }
  }

  &-itemNumber.eight {
    &::before {
      content: "8";
    }
  }

  &-itemNumber.nine {
    &::before {
      content: "9";
    }
  }

  &-itemNumber.ten {
    &::before {
      content: "10";
    }
  }

  &-itemNumber.eleven {
    &::before {
      content: "11";
    }
  }

  &-itemNumber.twelve {
    &::before {
      content: "12";
    }
  }

  &-itemNumber.thirteen {
    &::before {
      content: "13";
    }
  }
  &-itemNumber.fourteen {
    &::before {
      content: "14";
    }
  }

  &-itemNumber.fifteen {
    &::before {
      content: "15";
    }
  }

  &-itemNumber.sixteen {
    &::before {
      content: "16";
    }
  }

  &-itemNumber.seventeen {
    &::before {
      content: "17";
    }
  }

  &-itemNumber.eighteen {
    &::before {
      content: "18";
    }
  }
}

@media (max-width: 576px) {
  .List {
    list-style: none;

    &-itemNumber {
      padding-left: 30px;

      &::before {
        width: 20px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
