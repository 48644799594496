@font-face {
  font-family: 'Inter';
  src: url('../public/assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
  font-display: swap;
  /* Add additional font formats (e.g., woff, woff2) for better browser support */
}

@font-face {
  font-family: "Faktum Semi Bold";
  src: url("../public/assets/fonts/Inter/Faktum-Semi-Bold.woff2")
      format("truetype"),
    url("../public/assets/fonts/Inter/Faktum-Semi-Bold.woff") format("truetype");
    font-display: swap;
  /* Add additional font formats (e.g., woff, woff2) for better browser support */
}

@font-face {
  font-family: 'Google Sans';
  src: url('../public/assets/fonts/google/ProductSans-Medium.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f9ff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
