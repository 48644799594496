.navbar-expand-lg .navbar-nav .nav-link-landing {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 20px;
}

.nav-bordered {
  background-color: #3481cc;
  border-radius: 50px;
}

.nav-link-landing:hover {
  color: white;
}

.navbar > .container {
  align-items: flex-start;
}

.content {
  max-width: 95%;
}

.navbar-toggler {
  margin-right: 20px;
}

@media (max-width: 990px) {
  .navbar-nav {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    background: black;
    width: 100%;
    z-index: 20;
    padding-top: 132px;
  }

  .navbar > .container {
    padding: 0;
    margin: 0;
    max-width: 100%;
    align-items: center;
  }

  .navbar.active {
    overflow: hidden;
    position: fixed;
    z-index: 20;
    width: 100%;
  }

  .nav-link {
    font-size: 24px;
  }

  .nav-bordered {
    background-color: inherit;
    border-radius: 0;
  }
  
}
