@import '../variables.scss';

#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: get-color('midnight-blue');
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: get-color('nepal');
  font-weight: 600;
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}
