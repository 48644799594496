@import 'variables';
@import '~bootstrap/scss/bootstrap';

.btn {
    border-radius: 12px;
}

.main p {
    white-space: pre-wrap;
}

.no-padding-modal .modal-body {
    padding: 0;
    border: none;
    border-radius: 0;
    overflow: hidden;
    background: #000000;
}

.youtube-iframe {
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    height: 450px;
}

@media (max-width: 992px) {
    .youtube-iframe {
        height: 300px;
    }
}

@media (max-width: 576px) {
    .youtube-iframe {
        height: 250px;
    }
}
