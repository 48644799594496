@import "../variables.scss";

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: get-color("casper") transparent;
  box-shadow: 0px 0px 20px rgba(get-color("midnight-blue"), 0.1);
  background-color: get-color("white");
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-link.default-active {
  color: get-color("black");
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: get-color("royal-blue");
  background: get-color("zircon");
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: get-color("royal-blue");
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: get-color("midnight-blue");
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.bi-box-arrow-in-right::before {
  content: "\F1BE";
}

.nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: get-color("nepal");
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.btn-red {
  background-color: get-color("red");
  border: get-color("red") 1px solid;
}

.btn-red:hover {
  background-color: get-color("thunderbird");
  border: get-color("thunderbird") 1px solid;
}

.btn-red:disabled {
  background-color: get-color("geraldine");
  border: get-color("geraldine") 1px solid;
}
