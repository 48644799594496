.bodyDescription {
  font-size: 32px;
  line-height: 42px;
  color: white;
  padding-top: 50px;
  font-weight: 600;
  font-family: "Faktum Semi Bold";
}

.image {
    max-width: 100%;
}

@media (max-width: 576px) {
  .bodyDescription {
    font-size: 20px;
    line-height: 27px;
    padding-top: 30px;
  }
}
