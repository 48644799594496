@import '../variables.scss';

.error {
  color: get-color('maroon-flush');

  &::before {
    display: inline;
    content: "⚠ ";
  }
}

.link {
  text-decoration: none;
  color: get-color('black');
}

.loginButton {
  border: 2px solid get-color('danube');
  background-color: transparent;
  color: get-color('black');
}

.loginButton:hover {
  background-color: rgba(get-color('danube'), 0.1);
  color: get-color('black');
}
