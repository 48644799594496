@import "../variables.scss";

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: get-color("midnight-blue");
  font-family: Nunito, sans-serif;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(get-color("midnight-blue"), 0.1);
  background-color: get-color("white");
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: get-color("midnight-blue");
}

.navbar-burger {
  background-color: get-color("white");
  border-color: get-color("white");
  margin-left: 20%;
}

.cursor-pointer {
  cursor: pointer;
}

.unlimited {
  color: get-color("flamingo");;
  margin-bottom: 0;
}

.unlimited-text {
  margin-right: 30px;
  margin-bottom: 0;
}

.dropdown-profile {
  right: 20%;
  top: 196%;
}

.btn-blue.credits:hover {
  background-color: get-color("flamingo");
  border: get-color("flamingo") 1px solid;
}

@media (max-width: 992px) {
  .navbar-burger {
    margin-left: 0;
  }
}

.navbar-burger:hover {
  background-color: get-color("white");
  border-color: get-color("white");
}

.navbar-burger:focus {
  background-color: get-color("white");
  border-color: get-color("white");
}
